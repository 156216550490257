import { checkResponse, getRequestInit, getUrl } from 'app/api/api'
import { IQuotation } from 'app/models/quotations.models'

export const QuotationGetQuery = async (token: string): Promise<IQuotation> => {
  return checkResponse(
    await fetch(
      getUrl(`quotations/?token=${encodeURIComponent(token)}`, true),
      getRequestInit(false)
    )
  )
}
