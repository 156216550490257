import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Checkbox, Collapse, FormControl, FormControlLabel, TextField} from "@material-ui/core";
import {IService} from "../../../../app/models/booking.model";
import Typography from "@material-ui/core/Typography";
import {useOnView} from "../../../../app/providers/onview.provider";
import BookingStepComponent from "./bookingStep.component";

export interface IProps {
  currentCommonServices: number[]
  message: string
  setMessage: (message: string) => void
  targetCenter?: number
  setCurrentCommonServices: (currentCommonServices: number[]) => void
}

const BookingNeededComponent = (
  {
    currentCommonServices,
    message,
    setMessage,
    targetCenter,
    setCurrentCommonServices
  }: IProps): JSX.Element => {
  const {t} = useTranslation()
  const {listCommonMeetingServiceUseMutation} = useOnView()
  const [open, setOpen] = useState<boolean>(false)
  const [commonServices, setCommonServices] = useState<IService[] | undefined>(undefined)
  const [iconClass, setIconClass] = useState<string>('block-availability-header')

  useEffect(() => {
    loadCommonServiceList().then()

    return () => {
      setCommonServices(undefined)
    }
  }, [targetCenter])

  const onCheckCommonService = useCallback(
    (commonService: number) => {
      let _currentCommonService = [...currentCommonServices]
      let index = _currentCommonService.indexOf(commonService)

      if (index < 0) {
        _currentCommonService.push(commonService)
      } else {
        _currentCommonService.splice(index, 1)
      }

      setCurrentCommonServices(_currentCommonService)
    }, [currentCommonServices]
  )

  const loadCommonServiceList = useCallback(async () => {
    if (targetCenter !== undefined) {
      const _commonMeetingServices = await listCommonMeetingServiceUseMutation?.mutateAsync(undefined)
      if (undefined !== _commonMeetingServices) {
        setCommonServices(_commonMeetingServices)
      }
    }
  }, [targetCenter])

  useEffect(() => {
    setIconClass(open ? 'block-availability-header open' : 'block-availability-header')
  }, [open])

  return (
    <div className="block-availability mt-20">
      <div
        onClick={() => setOpen(!open)}
        className={iconClass}
      >
        <div className="flex-row">
          <BookingStepComponent
            step={6}
            text={t('common.booking_needed')}
          />
          <ExpandMoreIcon fontSize="small" className="mt-20"/>
        </div>
      </div>
      <Collapse in={open} className="block-availability-content">
        <FormControl
          variant="outlined"
          className="labelFormControl"
          size="small"
          fullWidth>
          {commonServices !== undefined && commonServices.map((service: IService, index: number) => (
            <FormControl key={index} variant="standard" className="labelFormControl"
                         size="small" fullWidth>
              <FormControlLabel
                control={<Checkbox color="primary" size="small"/>}
                label={service.label}
                labelPlacement="end"
                className="check-booking"
                value={currentCommonServices.includes(service.id)}
                onChange={() => onCheckCommonService(service.id)}
              />
            </FormControl>
          ))}
          <TextField
            id="message-multiline-flexible"
            placeholder={t('common.booking_textarea_message')}
            multiline
            fullWidth
            variant="outlined"
            value={message}
            onChange={(
              event: ChangeEvent<{
                name?: string | undefined
                value: unknown
              }>
            ) => setMessage(String(event.target.value))}
          />
          <Typography component="p" variant="caption" className="mt-15 text-italic">
            {t('common.booking_foodcard_condition')}
          </Typography>
        </FormControl>
      </Collapse>
    </div>
  );
};

export default BookingNeededComponent;