import React, {Fragment, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  Paper,
  Typography
} from '@material-ui/core'

import DateFormatter from 'app/formatters/date.formatter'
import CurrencyFormatter from 'app/formatters/currency.formatter'
import {IReservation} from 'app/models/booking.model'
import {ServiceTypeEnum} from 'app/enums/booking.enum'

export interface IProps {
  reservation?: IReservation
  serviceType: ServiceTypeEnum
  isLoadingPricer: boolean
  isLoadingValidate: boolean
  onValidate: () => void
}

const BookingSummaryComponent = ({
                                   reservation,
                                   serviceType,
                                   isLoadingPricer,
                                   isLoadingValidate,
                                   onValidate
                                 }: IProps): JSX.Element => {
  const {t} = useTranslation()
  const [confirmed, setConfirmed] = useState<boolean>(false)

  useEffect(() => {
    setConfirmed(false)
  }, [reservation])

  // Events UI
  const onConfirm = () => {
    setConfirmed(!confirmed)
  }

  return (
    <Grid item xs={12} md={4}>
      <Paper elevation={1}>
        <Box p={2}>
          <div className="myCard">
            <div className="card-header">
              <Typography variant="h2" color="primary">
                {t('common.booking_summary')}
              </Typography>
            </div>
            {isLoadingPricer && (
              <div className="myCardLoader">
                <CircularProgress color="secondary"/>
              </div>
            )}

            {!isLoadingPricer && reservation !== undefined && (
              <Fragment>
                <List>
                  <ListItem>
                    {t('common.center')}
                    <ListItemSecondaryAction>
                      <strong>{reservation.center}</strong>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {serviceType === ServiceTypeEnum.MEETING && (
                    <>
                      <ListItem>
                        {t('common.booking_select_meeting_room')}
                      </ListItem>
                      <ListItem>
                        <ListItemSecondaryAction>
                          <strong>{reservation.service.label + ' - ' + CurrencyFormatter.formatFr(reservation.service.price)}</strong>
                        </ListItemSecondaryAction>
                      </ListItem>
                      {reservation.commonServices !== undefined && reservation.commonServices.length > 0 && (
                        <>
                          <ListItem>
                            {'Service supplémentaires'}
                          </ListItem>
                          {reservation.commonServices.map((service, index) => {
                            return (
                              <ListItem key={index}>
                                <ListItemSecondaryAction>
                                  <strong>{service.label + ' x ' + reservation.nbPerson + ' - ' + CurrencyFormatter.formatFr(service.price)}</strong>
                                </ListItemSecondaryAction>
                              </ListItem>
                            )
                          })}
                        </>
                      )}
                    </>
                  ) || serviceType === ServiceTypeEnum.OPEN_DESKTOP && (
                    <>
                      <ListItem>
                        {t('common.booking_select_open_desktop')}
                      </ListItem>
                      <ListItem>
                        <ListItemSecondaryAction>
                          <strong>{t('common.booking_select_open_desktop') + ' - ' + CurrencyFormatter.formatFr(reservation.service.price)}</strong>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </>
                  )}
                  <ListItem>
                    {t('common.date').charAt(0).toUpperCase() + t('common.date').slice(1)}
                    <ListItemSecondaryAction>
                      <strong>{DateFormatter.formatFrDateFromString(reservation.begin)}</strong>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    {t('common.booking_hours').charAt(0).toUpperCase() +
                      t('common.booking_hours').slice(1)}
                    <ListItemSecondaryAction>
                      {t('common.from')} &nbsp;
                      <strong>
                        {DateFormatter.formatFrRoundHourFromString(reservation.begin)}
                      </strong>{' '}
                      &nbsp; {t('common.to')} &nbsp;
                      <strong>{DateFormatter.formatFrRoundHourFromString(reservation.end)}</strong>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider/>
                  <ListItem>
                    {t('common.booking_price')}
                    <ListItemSecondaryAction>
                      <strong>{CurrencyFormatter.formatFr(reservation.price)}</strong>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {reservation.reductionRate > 0 && (
                    <Fragment>
                      <ListItem>
                        {t('common.reduction_main_service')}
                        <ListItemSecondaryAction>
                          <strong>
                            {reservation.reductionRate}% &nbsp; (
                            {CurrencyFormatter.formatFr(reservation.reduction)})
                          </strong>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Fragment>
                  )}
                  <Divider/>
                  <ListItem className="bg-gray-booking-light">
                    {t('common.booking_billing_ht')}
                    <ListItemSecondaryAction className="bg-gray-booking-light-action">
                      <strong>{CurrencyFormatter.formatFr(reservation.amount)}</strong>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem className="bg-gray-booking-light">
                    {t('common.booking_billing_tva')}
                    <ListItemSecondaryAction className="bg-gray-booking-light-action">
                      {CurrencyFormatter.formatFr(reservation.vat)}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem className="bg-secondary">
                    {t('common.booking_billing_ttc')}
                    <ListItemSecondaryAction className="bg-secondary-action">
                      <strong>{CurrencyFormatter.formatFr(reservation.total)}</strong>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
                <FormControl variant="standard" className="labelFormControl" size="small" fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="primary" size="small"/>}
                    label={t('common.contract_pending_cgv_check')}
                    labelPlacement="end"
                    className="check-booking"
                    value={confirmed}
                    onChange={onConfirm}
                  />
                </FormControl>
                <FormControl variant="standard" className="labelFormControl" size="small" fullWidth>
                  <button
                    type="button"
                    className="button"
                    onClick={onValidate}
                    disabled={!confirmed}>
                    {!isLoadingValidate && t('common.validate')}

                    {isLoadingValidate && (
                      <CircularProgress color="secondary" className="small-loader"/>
                    )}
                  </button>
                </FormControl>
              </Fragment>
            )}
            {reservation === undefined && !isLoadingPricer && (
              <div className="text-center pt-30">{t('common.cart_is_empty_for_the_moment')}</div>
            )}
          </div>
        </Box>
      </Paper>
    </Grid>
  )
}

export default BookingSummaryComponent
