import React, {ChangeEvent, useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import BookingStepComponent from './bookingStep.component'
import {FormControl, Select} from '@material-ui/core'
import {IcenterSelect} from 'app/models/centers.model'
import {useOnLogin} from 'app/providers/onlogin.provider'

interface IProps {
  targetCenter?: number
  targetCenterSelect: (centerId?: number) => void
}

const BookingSelectCenterComponent = ({targetCenter, targetCenterSelect}: IProps): JSX.Element => {
  const {t} = useTranslation()
  const {centers, userCenterId} = useOnLogin()

  const onChangeCenter = useCallback(
      (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        if (undefined !== centers && !isNaN(Number(event.target.value))) {
          let selected = centers.filter(
              (center: IcenterSelect) => Number(center.id) == Number(event.target.value)
          )
          targetCenterSelect(selected[0].id)
        } else {
          targetCenterSelect(undefined)
        }
      },
      [centers]
  )

  useEffect(() => {
    if (undefined !== userCenterId && undefined !== centers && centers.length > 0) {
      const _center = centers!.find((center) => center.id === userCenterId)
      if (undefined !== _center) {
        targetCenterSelect(_center.id)
      } else {
        targetCenterSelect(centers[0].id)
      }
    }
  }, [userCenterId, centers])

  return (
    <FormControl
      variant="standard"
      className="labelFormControl"
      size="small"
      fullWidth>
      <BookingStepComponent
        step={1}
        text={t('common.center')}
      />
      <Select native value={targetCenter} onChange={onChangeCenter}>
        {undefined !== centers && centers.map((center: { id?: number, name: string }, index: number) => (
          <option key={index} value={center.id}>
            {center.name}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

export default BookingSelectCenterComponent
