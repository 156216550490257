import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'

import { IQuotation } from 'app/models/quotations.models'

import QuotationDetailsComponent from 'modules/quotations/components/header/quotationDetails.component'

export interface IProps {
  quotation?: IQuotation
}

const QuotationDetailsCardComponent = ({ quotation }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className="myCard">
      <div className="card-header">
        <Typography variant="h2" color="primary">
          {t('common.your_quotation_flexo')} {quotation!.center}
        </Typography>
      </div>
      <div className="card-content">
        <QuotationDetailsComponent quotation={quotation!} />
      </div>
    </div>
  )
}

export default QuotationDetailsCardComponent
