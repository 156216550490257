import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { useApp } from 'app/providers/app.provider'

export interface IProps {
  bookingListLink: string
}

const BookingHeaderComponent = ({ bookingListLink }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const { fromApp } = useApp()

  return (
    <Box component='div' px={2} py={4} className='small-y'>
      {!fromApp && (
        <>
          <Typography variant='h2' gutterBottom={true}>
            {t('common.booking_online')}
          </Typography>
          <Divider />
        </>
      )}
      <Link to={bookingListLink}
            className={`button bg-white ${fromApp ? 'center' : ''}`}>{t('common.my_booking')}</Link>
    </Box>
  )
}

export default BookingHeaderComponent
