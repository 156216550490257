import React from 'react'
import {Box, Grid, Paper, Typography} from '@material-ui/core'
import {useTranslation} from "react-i18next";

export interface IProps {
  message: string[]
}

const BookingErrorComponent = ({message}: IProps): JSX.Element => {
  const {t} = useTranslation()
  return (
    <Grid item xs={12} md={4}>
      <Paper elevation={1}>
        <Box p={2}>
          <div className="myCard">
            <div className="card-header">
              <Typography variant="h2" color="primary">
                {t('common.booking_summary')}
              </Typography>
            </div>
            <div className="text-center pt-30">
              {message.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>
          </div>
        </Box>
      </Paper>
    </Grid>
  )
}

export default BookingErrorComponent
