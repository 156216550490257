import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Collapse } from '@material-ui/core'

export interface IProps {
  beginHours: number[]
  hours: number[]
}

const BookingAvailabilityComponent = ({ beginHours, hours }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(true)

  const checkAvailability = (hour: number, key: number, length: number) => {
    if (hours.includes(hour)) {
      return (
        <Fragment key={key}>
          <span className="block-hour">
            {hour}h - {hour + 1}h
          </span>
          {length !== key && <span> | </span>}
        </Fragment>
      )
    }
    return (
      <Fragment key={key}>
        <span className="block-hour block-not-availability-hour">
          {hour}h - {hour + 1}h
        </span>
        {length !== key && <span> | </span>}
      </Fragment>
    )
  }

  return (
    <div className="block-availability">
      <div
        onClick={() => setOpen(!open)}
        className={open ? 'block-availability-header open' : 'block-availability-header'}>
        <ExpandMoreIcon fontSize="small" />
        {t('common.booking_availability')}
      </div>
      <Collapse in={open} className="block-availability-content">
        {beginHours.map((hour: number, key: number) =>
          checkAvailability(hour, key, beginHours.length - 1)
        )}
      </Collapse>
    </div>
  )
}

export default BookingAvailabilityComponent
