import React, {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react'
import {Box, FormControl, Grid, Paper, Select, TextField} from '@material-ui/core'
import {useTranslation} from 'react-i18next'

import DateFormatter from 'app/formatters/date.formatter'
import Session from 'app/libraries/session.lib'
import {useApp} from 'app/providers/app.provider'
import {
  INomadeCounter,
  INomadeCounterForPricing,
  IReservation,
  IReservationForPricing,
  IScheduleDesktop,
  IService
} from 'app/models/booking.model'
import {useOnLogin} from 'app/providers/onlogin.provider'
import {useOnView} from 'app/providers/onview.provider'

import BookingSummaryComponent from 'modules/booking/components/booking/bookingSummary.component'
import BookingStepComponent from 'modules/booking/components/booking/bookingStep.component'
import BookingAvailabilityComponent from 'modules/booking/components/booking/bookingAvailability.component'
import BookingModalComponent from 'modules/booking/components/booking/bookingModal.component'
import BookingHeaderComponent from 'modules/booking/components/booking/bookingHeader.component'
import BookingSelectCenterComponent from 'modules/booking/components/booking/bookingSelectCenter.component'

import 'modules/booking/css/booking.scss'
import {ServiceTypeEnum} from 'app/enums/booking.enum'
import BookingErrorComponent from 'modules/booking/components/booking/bookingError.component'
import BookingNeededComponent from '../components/booking/BookingNeeded.component'
import BookingEnterpriseComponent from '../components/booking/bookingEnterprise.component'
import BookingTypeComponent from '../components/booking/bookingType.component'
import {ICenter} from '../../../app/models/centers.model'

// TODO: Organization - Récupérer les horaires via API
const beginHours = [9, 10, 11, 12, 13, 14, 15, 16, 17]

const Booking = (): JSX.Element => {
  const {t} = useTranslation()
  const {fromApp} = useApp()
  const {currentRole, currentEnterprise, userCenterId, roles, centers} = useOnLogin()
  const {
    listMeetingServiceUseMutation,
    customerReservationCreateUseMutation,
    listScheduleDesktopUseMutation,
    pricingCustomerReservationUseMutation,
    nomadeCounterUseMutation,
  } = useOnView()

  const [services, setServices] = useState<IService[]>([])
  const [currentService, setCurrentService] = useState<IService | undefined>(undefined)
  const [currentCommonServices, setCurrentCommonServices] = useState<number[]>([])
  const [currentDate, setCurrentDate] = useState<string>('')
  const [minDate, setMinDate] = useState<string>('')
  const [maxDate, setMaxDate] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [beginHour, setBeginHour] = useState<number>(0)
  const [endHour, setEndHour] = useState<number>(0)
  const [currentPricing, setCurrentPricing] = useState<IReservation | undefined>(undefined)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [messageModal, setMessageModal] = useState<string>('')
  const [availableBeginHours, setAvailableBeginHours] = useState<number[]>([])
  const [availableEndHours, setAvailableEndHours] = useState<number[]>([])
  const [isLoadingPricer, setIsLoadingPricer] = useState<boolean>(false)
  const [isLoadingValidate, setIsLoadingValidate] = useState<boolean>(false)
  const [serviceType, setServiceType] = useState<ServiceTypeEnum>(ServiceTypeEnum.MEETING)
  const [nomadeCounter, setNomadeCounter] = useState<INomadeCounter | undefined>(undefined)
  const [targetCenter, setTargetCenter] = useState<number | undefined>(userCenterId)
  const [nbPerson, setNbPerson] = useState<number>(1)
  const [currentEnterpriseLabel, setCurrentEnterpriseLabel] = useState<string>('')
  const [errorPricing, setErrorPricing] = useState<string[] | undefined>(undefined)

  const bookingListLink = useMemo(
    () => (fromApp ? '/booking_list?fromApp=1' : '/booking_list'),
    [fromApp]
  )

  useEffect(() => {
    if (currentEnterprise !== undefined && roles !== undefined && roles.length > 0) {
      const [_role] = roles.filter((role) => role.entreprise_id === currentEnterprise)
      setCurrentEnterpriseLabel(_role.enterprise_name)
    } else {
      setCurrentEnterpriseLabel('')
    }
  }, [currentEnterprise, roles])

  useEffect(() => {
    initDateFromServiceType()
  }, [])

  useEffect(() => {
    loadServiceList().then()
  }, [targetCenter])

  // En cas de changement de l'heure de début
  useEffect(() => {
    if (beginHour > 0) {
      getEndHourMax()
    }

    if (endHour <= beginHour) {
      setEndHour(0)
      setCurrentPricing(undefined)
      setErrorPricing(undefined)
    }
  }, [beginHour])

  // En cas de changement de l'heure de fin
  useEffect(() => {
    if (beginHour > 0 && endHour > 0) {
      pricing().then()
    }
  }, [beginHour, endHour, currentCommonServices, nbPerson, currentEnterprise])

  // En cas de changement de type de service ou de date
  useEffect(() => {
    if (serviceType === ServiceTypeEnum.OPEN_DESKTOP && currentDate != '') {
      getNomadeCounter().then()
      setAvailableBeginHours(beginHours)
    }
  }, [serviceType, targetCenter, currentDate])

  // En cas de changement de centre, de date, de service ou de type de service
  useEffect(() => {
    setCurrentPricing(undefined)
    setErrorPricing(undefined)
    setBeginHour(0)
    setEndHour(0)
    setMessage('')
    setIsLoadingPricer(false)
    setIsLoadingValidate(false)
    setNomadeCounter(undefined)
    setNbPerson(1)
    setCurrentCommonServices([])
    if (serviceType === ServiceTypeEnum.MEETING) {
      setAvailableBeginHours([])
      setAvailableEndHours([])
      getScheduleList().then()
    }
  }, [currentService, currentDate, serviceType, targetCenter])

  useEffect(() => {
    initDateFromServiceType()
    setNbPerson(1)
  }, [serviceType])

  const loadServiceList = useCallback(async () => {
    if (targetCenter !== undefined) {
      const _services = await listMeetingServiceUseMutation?.mutateAsync(targetCenter)
      if (undefined !== _services) {
        // Sort the services by capacity
        _services.sort((a, b) => {
          return a.capacity - b.capacity
        })
        setServices(_services)
        if (_services.length > 0) {
          setCurrentService(_services[0])
        }
      }
    } else {
      setCurrentService(undefined)
    }
  }, [targetCenter])

  const onChangeDate = useCallback(
    (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      let date = new Date(String(event.target.value))
      if (date !== undefined) {
        setCurrentDate(DateFormatter.formatUSDate(date))
      }
    },
    []
  )

  const onChangeService = useCallback(
    (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      let selected = services.filter(
        (service: IService) => Number(service.id) == Number(event.target.value)
      )
      setCurrentService(selected[0])
    },
    [services]
  )

  const onChangeNbPerson = useCallback(
    (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      setNbPerson(Number(event.target.value))
    },
    [nbPerson]
  )

  const initDateFromServiceType = (): void => {
    let dateNow = new Date()
    let dateLimit = new Date()
    setCurrentDate(DateFormatter.formatUSDate(dateNow))
    setMinDate(DateFormatter.formatUSDate(dateNow))
    switch (serviceType) {
      case ServiceTypeEnum.MEETING:
        dateLimit = new Date(dateLimit.setMonth(dateLimit.getMonth() + 3))
        break
      case ServiceTypeEnum.OPEN_DESKTOP:
        dateLimit = new Date(dateLimit.setDate(dateLimit.getDate() + 30))
    }
    setMaxDate(DateFormatter.formatUSDate(dateLimit))
  }

  const onValidate = useCallback(async () => {
    setIsLoadingValidate(true)
    let reservationForPricing = getReservationForPricing(true)
    if (reservationForPricing !== undefined) {
      try {
        await customerReservationCreateUseMutation?.mutateAsync(reservationForPricing)
        setMessageModal(t('common.booking_success'))
      } catch (error: any) {
        setMessageModal(error.error ? error.error : t('common.error.unknown'))
      } finally {
        setShowModal(true)
        setIsLoadingValidate(false)
      }
    }
  }, [currentRole, currentService, currentDate, beginHour, endHour, targetCenter, message, currentCommonServices, nbPerson])

  const onCloseModal = useCallback(() => {
    setMessageModal('')
    setShowModal(false)
    window.location.href = bookingListLink
  }, [])

  const getScheduleList = useCallback(async () => {
    if (currentService !== undefined) {
      let schedules = await listScheduleDesktopUseMutation?.mutateAsync({
        service: currentService.id,
        date: currentDate,
        enterpriseId: currentEnterprise
      })
      if (undefined !== schedules) {
        let beginHoursTmp: number[] = []
        schedules.map((schedule: IScheduleDesktop) => {
          let end = Number(schedule.end) + 1
          for (let i = Number(schedule.begin); i < end; ++i) {
            if (!beginHoursTmp.includes(i)) {
              beginHoursTmp.push(Number(i))
            }
          }
        })
        setAvailableBeginHours(beginHours.filter((x) => !beginHoursTmp.includes(x)))
      }
    }
  }, [currentDate, currentService, currentRole, currentEnterprise])

  const pricing = useCallback(async () => {
    setErrorPricing(undefined)
    setIsLoadingPricer(true)
    let reservationForPricing = await getReservationForPricing()
    if (reservationForPricing !== undefined) {
      try {
        let response = await pricingCustomerReservationUseMutation?.mutateAsync({
          enterpriseId: currentEnterprise,
          body: reservationForPricing
        })
        setCurrentPricing(response)
      } catch (error: any) {
        const code = error.code
        let errorMessage = error.error
        switch(code) {
          case 400:
            setCurrentPricing(undefined)
            setErrorPricing(errorMessage ? [errorMessage] : [t('common.bookingView.withoutRights'), currentEnterpriseLabel])
            break
          case 404: {
            setCurrentPricing(undefined)
            const [_center] = centers!.filter((center: ICenter) => center.id === targetCenter)
            setErrorPricing(errorMessage ? [errorMessage] : [t('common.bookingView.noRoomsAvailable', {phone: _center.phone})])
            break
          }
          default:
            setCurrentPricing(undefined)
            setErrorPricing(errorMessage ? [errorMessage] : [t('common.error.unknown')])
        }
      }
    }
    setIsLoadingPricer(false)
  }, [currentEnterprise, currentRole, beginHour, endHour, currentCommonServices, nbPerson, centers, targetCenter])

  const getNomadeCounter = useCallback(async () => {
    setIsLoadingPricer(true)
    let nomadeCounterForPricing = await getNomadeCounterForPricing()
    if (nomadeCounterForPricing !== undefined) {
      let response = await nomadeCounterUseMutation?.mutateAsync({
        enterpriseId: currentEnterprise,
        body: nomadeCounterForPricing
      })
      setNomadeCounter(response)
    }
    setIsLoadingPricer(false)
  }, [currentEnterprise, currentRole, beginHour, endHour, currentDate])

  const getEndHourMax = useCallback(() => {
    let stop = false
    let availableEndHoursTmp: number[] = []
    let lastBegin = beginHour + 1
    let beginTmp = availableBeginHours.filter((x) => x >= beginHour)
    beginTmp.map((x: number, key: number) => {
      if (!stop && beginTmp[key + 1] !== undefined && beginTmp[key + 1] === lastBegin) {
        availableEndHoursTmp.push(lastBegin)
        ++lastBegin
      } else {
        stop = true
      }
    })
    availableEndHoursTmp.push(lastBegin)
    setAvailableEndHours(availableEndHoursTmp)
  }, [beginHour, availableBeginHours])

  const getNomadeCounterForPricing = useCallback((): INomadeCounterForPricing | undefined => {
    if (targetCenter !== undefined) {
      let date = new Date(currentDate)
      if (Session.getUser() !== undefined) {
        return {
          individualId: Session.getUser()!.individual_id,
          center: targetCenter,
          date: DateFormatter.formatUSDateTimeJson(date),
        }
      }
    }
    return undefined
  }, [currentDate, targetCenter])

  const getReservationForPricing = useCallback(
    (validate = false): IReservationForPricing | undefined => {
      if (targetCenter !== undefined && currentService !== undefined) {
        let beginDate = new Date(currentDate)
        let endDate = new Date(currentDate)
        beginDate.setHours(beginHour, 0, 0)
        endDate.setHours(endHour - 1, 59, 59)
        if (Session.getUser() !== undefined) {
          return {
            individualId: Session.getUser()!.individual_id,
            center: targetCenter,
            service: serviceType === ServiceTypeEnum.OPEN_DESKTOP ? undefined : currentService.id,
            nbPerson: nbPerson,
            begin: DateFormatter.formatUSDateTimeJson(beginDate),
            end: DateFormatter.formatUSDateTimeJson(endDate),
            commonServices: currentCommonServices,
            message: validate ? message : undefined,
            serviceType: serviceType
          }
        }
      }
      return undefined
    },
    [currentService, currentDate, beginHour, endHour, targetCenter, message, currentCommonServices, nbPerson]
  )

  return (
    <>
      <BookingModalComponent open={showModal} message={messageModal} onClose={onCloseModal}/>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <Paper elevation={1} className="paperHelpdesk">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} className="bkColImgBk">
                <BookingHeaderComponent bookingListLink={bookingListLink}/>
              </Grid>
              <Grid item xs={12} md={8} className="small-y">
                <Box component="div" p={2} className="pt-1">
                  <div className="myCard">
                    <Grid container spacing={2} className="mb-20">
                      {roles.length > 1 &&
                        <BookingEnterpriseComponent currentEnterpriseLabel={currentEnterpriseLabel}/>
                      }
                      <BookingTypeComponent serviceType={serviceType} setServiceType={setServiceType}/>
                    </Grid>
                    {
                      (() => {
                        if (serviceType === ServiceTypeEnum.MEETING) {
                          return (
                            <>
                              <Grid container spacing={2} className="mb-20">
                                <Grid item xs={12} md={6}>
                                  <BookingSelectCenterComponent targetCenter={targetCenter}
                                                                targetCenterSelect={(centerId?: number) => setTargetCenter(centerId)}/>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} className="mb-20">
                                <Grid item xs={12} md={6}>
                                  <FormControl
                                    variant="standard"
                                    className="labelFormControl"
                                    size="small"
                                    fullWidth>
                                    <BookingStepComponent step={2} text={t('common.booking_select_day')}/>
                                    <TextField
                                      variant="standard"
                                      type="date"
                                      size="small"
                                      value={currentDate}
                                      onChange={onChangeDate}
                                      inputProps={{min: minDate, max: maxDate}}
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} className="mb-20">
                                <Grid item xs={12} md={6}>
                                  {currentService !== undefined && (
                                    <FormControl
                                      variant="standard"
                                      className="labelFormControl"
                                      size="small"
                                      fullWidth>
                                      <BookingStepComponent
                                        step={3}
                                        text={t('common.booking_select_meeting_room')}
                                      />
                                      <Select native value={currentService.id} onChange={onChangeService}>
                                        {services.map((service: IService, index: number) => (
                                          <option key={index} value={service.id}>
                                            {service.label}
                                            &nbsp;
                                            {service.id > 0 ? `- ${service.capacity} ${t('common.booking_capacity')}` : ''}
                                          </option>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} className="mb-20">
                                <Grid item xs={12} md={6}>
                                  {currentService !== undefined && (
                                    <FormControl
                                      variant="standard"
                                      className="labelFormControl"
                                      size="small"
                                      fullWidth>
                                      <BookingStepComponent
                                        step={4}
                                        text={t('common.booking_select_number_of_person')}
                                      />

                                      <Select native value={nbPerson} onChange={onChangeNbPerson}>
                                        {
                                          new Array(currentService.capacity).fill(0).map((_, index) => (
                                            <option key={index} value={index + 1}>
                                              {index + 1 + ' ' + (index + 1 > 1 ? t('common.person.label_other') : t('common.person.label_one')).toLowerCase()}
                                            </option>
                                          ))
                                        }
                                      </Select>
                                    </FormControl>
                                  )}
                                </Grid>
                              </Grid>
                              {availableBeginHours.length === 0 && (
                                <div>{t('common.booking_not_available')}</div>
                              )}
                              {availableBeginHours.length > 0 && (
                                <>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                      {currentService !== undefined && (
                                        <BookingAvailabilityComponent
                                          hours={availableBeginHours}
                                          beginHours={beginHours}
                                        />
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                      {currentService !== undefined && (
                                        <>
                                          <BookingStepComponent step={5} text={t('common.booking_schedule')}/>
                                          <Grid container spacing={2}>
                                            <Grid item xs={6} md={6}>
                                              <FormControl
                                                variant="standard"
                                                className="labelFormControl"
                                                size="small"
                                                fullWidth>
                                                <Select
                                                  native
                                                  value={beginHour}
                                                  onChange={(
                                                    event: ChangeEvent<{
                                                      name?: string | undefined
                                                      value: unknown
                                                    }>
                                                  ) => setBeginHour(Number(event.target.value))}>
                                                  <option value={0}>
                                                    {t('common.booking_select_hour_start')}
                                                  </option>
                                                  {availableBeginHours.map((hour: number, key: number) => (
                                                    <option key={key} value={hour}>
                                                      {hour}h
                                                    </option>
                                                  ))}
                                                </Select>
                                              </FormControl>
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                              <FormControl
                                                variant="standard"
                                                className="labelFormControl"
                                                size="small"
                                                fullWidth>
                                                <Select
                                                  native
                                                  value={endHour}
                                                  onChange={(
                                                    event: ChangeEvent<{
                                                      name?: string | undefined
                                                      value: unknown
                                                    }>
                                                  ) => setEndHour(Number(event.target.value))}>
                                                  <option value={0}>
                                                    {t('common.booking_select_hour_end')}
                                                  </option>
                                                  {availableEndHours.map((hour: number, key: number) => (
                                                    <option key={key} value={hour}>
                                                      {hour}h
                                                    </option>
                                                  ))}
                                                </Select>
                                              </FormControl>
                                            </Grid>
                                          </Grid>
                                        </>
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                      {currentService !== undefined && (
                                        <BookingNeededComponent
                                          message={message}
                                          currentCommonServices={currentCommonServices}
                                          setMessage={setMessage}
                                          targetCenter={targetCenter}
                                          setCurrentCommonServices={setCurrentCommonServices}
                                        />
                                      )}
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </>
                          )
                        } else {
                          return (
                            <>
                              <Grid container spacing={2} className="mb-20">
                                <Grid item xs={12} md={12}>
                                  <BookingSelectCenterComponent targetCenter={targetCenter}
                                                                targetCenterSelect={(centerId?: number) => setTargetCenter(centerId)}/>
                                </Grid>
                              </Grid>
                              {currentService !== undefined && (
                                <>
                                  <Grid container spacing={2} className="mb-20">
                                    <Grid item xs={12} md={12}>
                                      <FormControl
                                        variant="standard"
                                        className="labelFormControl"
                                        size="small"
                                        fullWidth>
                                        <BookingStepComponent step={2} text={t('common.booking_select_day')}/>
                                        <TextField
                                          variant="standard"
                                          type="date"
                                          size="small"
                                          value={currentDate}
                                          onChange={onChangeDate}
                                          inputProps={{min: minDate, max: maxDate}}
                                        />
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                  {nomadeCounter !== undefined && nomadeCounter.countReservation !== undefined && (
                                    <>
                                      {nomadeCounter.maxFreeReservation !== null && nomadeCounter.countReservation < nomadeCounter.maxFreeReservation && (
                                        <Grid item xs={12} md={12}>
                                          <Box component={'p'} className="bg-info" px={1} py={1} borderRadius={4}>
                                            {t('common.booking_nomade_counter', {
                                              countReservation: nomadeCounter.countReservation,
                                              maxFreeReservation: nomadeCounter.maxFreeReservation,
                                              nomadeLabel: nomadeCounter.nomadeLabel
                                            })}
                                          </Box>
                                        </Grid>
                                      )}
                                      {nomadeCounter.maxFreeReservation === null && (
                                        <Grid item xs={12} md={12}>
                                          <Box component={'p'} className="bg-info" px={1} py={1} borderRadius={4}>
                                            {t('common.booking_nomade_200_counter', {
                                              countReservation: nomadeCounter.countReservation,
                                              nomadeLabel: nomadeCounter.nomadeLabel
                                            })}
                                          </Box>
                                        </Grid>
                                      )}
                                    </>
                                  )}
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                      <BookingStepComponent step={3} text={t('common.booking_schedule')}/>
                                      <Grid container spacing={2}>
                                        <Grid item xs={6} md={6}>
                                          <FormControl
                                            variant="standard"
                                            className="labelFormControl"
                                            size="small"
                                            fullWidth>
                                            <Select
                                              native
                                              value={beginHour}
                                              onChange={(
                                                event: ChangeEvent<{
                                                  name?: string | undefined
                                                  value: unknown
                                                }>
                                              ) => setBeginHour(Number(event.target.value))}>
                                              <option value={0}>
                                                {t('common.booking_select_hour_start')}
                                              </option>
                                              {availableBeginHours.map((hour: number, key: number) => (
                                                <option key={key} value={hour}>
                                                  {hour}h
                                                </option>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                          <FormControl
                                            variant="standard"
                                            className="labelFormControl"
                                            size="small"
                                            fullWidth>
                                            <Select
                                              native
                                              value={endHour}
                                              onChange={(
                                                event: ChangeEvent<{
                                                  name?: string | undefined
                                                  value: unknown
                                                }>
                                              ) => setEndHour(Number(event.target.value))}>
                                              <option value={0}>
                                                {t('common.booking_select_hour_end')}
                                              </option>
                                              {availableEndHours.map((hour: number, key: number) => (
                                                <option key={key} value={hour}>
                                                  {hour}h
                                                </option>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                      <FormControl
                                        variant="outlined"
                                        className="labelFormControl"
                                        size="small"
                                        fullWidth>
                                        <BookingStepComponent step={4} text={t('common.booking_needed')}/>
                                        <TextField
                                          id="message-multiline-flexible"
                                          placeholder={t('common.booking_textarea_message')}
                                          multiline
                                          fullWidth
                                          variant="outlined"
                                          value={message}
                                          onChange={(
                                            event: ChangeEvent<{
                                              name?: string | undefined
                                              value: unknown
                                            }>
                                          ) => setMessage(String(event.target.value))}
                                        />
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </>
                          )
                        }
                      })()
                    }
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {errorPricing === undefined && (
          <BookingSummaryComponent
            reservation={currentPricing}
            serviceType={serviceType}
            isLoadingPricer={isLoadingPricer}
            isLoadingValidate={isLoadingValidate}
            onValidate={onValidate}
          />
        )}
        {errorPricing !== undefined && (
          <BookingErrorComponent
            message={errorPricing}
          />
        )}

      </Grid>
    </>
  )
}

export default Booking
