import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import reportWebVitals from 'reportWebVitals'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next.lib'
import OnLoginProvider from 'app/providers/onlogin.provider'
import AppProvider from 'app/providers/app.provider'
import OnViewProvider from 'app/providers/onview.provider'
import AppView from 'app/views/app.view'
import 'css/index.scss'
import AppThemeProvider from "app/providers/apptheme.provider";

ReactDOM.render(
  <QueryClientProvider client={new QueryClient()}>
    <I18nextProvider i18n={i18next}>
        <AppProvider>
            <AppThemeProvider>
                  <OnLoginProvider>
                    <OnViewProvider>
                      <AppView />
                    </OnViewProvider>
                  </OnLoginProvider>
            </AppThemeProvider>
        </AppProvider>
    </I18nextProvider>
  </QueryClientProvider>,
  document.getElementById('root')
)

reportWebVitals()
