import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Viewer, Worker } from '@react-pdf-viewer/core'

import DocumentHelper from 'app/helpers/document.helper'
import { useOnView } from 'app/providers/onview.provider'
import { useOnLogin } from 'app/providers/onlogin.provider'

export interface IProps {
  id: number
}

const DocumentDetailsPdfComponent = ({ id }: IProps): JSX.Element => {
  const { currentEnterprise } = useOnLogin()
  const { documentDownloadUseMutation } = useOnView()

  const [url, setUrl] = useState<string | undefined>()

  useEffect(() => {
    getPdf().then()
  }, [id])

  const getPdf = useCallback(async () => {
    let _url = undefined
    let _file = await documentDownloadUseMutation?.mutateAsync({
      enterpriseId: currentEnterprise,
      id: id
    })
    if (undefined !== _file) {
      let blob = DocumentHelper.base64toBlob(_file.content, _file.mimetype)
      _url = URL.createObjectURL(blob)
    }
    setUrl(_url)
  }, [currentEnterprise, id])

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      <Grid item xs={12} md={8}>
        {url !== undefined && (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <div
              style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                height: 'auto'
              }}>
              <Viewer fileUrl={url} />
            </div>
          </Worker>
        )}
      </Grid>
    </Grid>
  )
}

export default DocumentDetailsPdfComponent
