import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { AppContextType } from 'app/types/providers.type'
import { ExceptionEnum } from 'app/enums/exceptions.enum'
import { IAlertMessage } from 'app/models/alert_message.model'
import Session from 'app/libraries/session.lib'
import { SeverityEnum } from 'app/enums/app.enum'
import {ChangePasswordQuery, ForgottenPasswordQuery} from 'app/api/connection.api'
import {IChangePassword} from "../models/account.model";

interface IProps {
  children: ReactNode
}

const AppContext = createContext<AppContextType>({
  isConnected: false,
  setIsConnected: () => undefined,
  throwAppException: () => undefined,
  onError: false,
  setOnError: () => undefined,
  alertMessage: undefined,
  setAlertMessage: () => undefined,
  onLogout: false,
  setOnLogout: () => undefined,
  fromApp: false,
  forgottenPasswordUseMutation: undefined,
  changePasswordUseMutation: undefined
})

const AppProvider = ({ children }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const queryParams = new URLSearchParams(window.location.search)

  const [isConnected, setIsConnected] = useState<boolean>(false)
  const [onError, setOnError] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<IAlertMessage | undefined>()
  const [onLogout, setOnLogout] = useState<boolean>(false)
  const [fromApp, setFromApp] = useState<boolean>(false)

  useEffect(() => {
    const _fromApp = queryParams.get('fromApp')
    setFromApp(_fromApp !== undefined && _fromApp !== null && _fromApp === '1')
  }, [])

  useEffect(() => {
    if (onLogout) {
      setIsConnected(false)
      const darkMode = Session.getIsDarkMode();
      Session.clear()
      Session.setIsDarkMode(darkMode);
      window.location.href = '/login'
      setOnLogout(false)
    }
  }, [onLogout])

  const throwAppException = useCallback((status?: ExceptionEnum, message?: IAlertMessage) => {
    switch (status) {
      case 401:
        if (window.location.pathname.split('/', 2)[1] === 'login') {
          setAlertMessage({
            severity: SeverityEnum.ERROR,
            message: t('common.credentials.invalid'),
            timeout: 6000
          })
          setOnError(true)
        } else {
          setOnLogout(true)
        }
        break
      case 403:
        setOnError(true)
        if (undefined !== message) {
          setAlertMessage(message)
        } else {
          setAlertMessage({
            severity: SeverityEnum.ERROR,
            message: t('common.error.unknown'),
            timeout: 6000
          })
          window.location.href = '/home'
        }
        break
      default:
        setOnError(true)
        if (undefined !== message) {
          setAlertMessage(message)
        } else {
          setAlertMessage({
            severity: SeverityEnum.ERROR,
            message: t('common.error.unknown'),
            timeout: 6000
          })
        }
    }
  }, [])

  const forgottenPasswordUseMutation = useMutation<void, unknown, string>(
    'forgottenPassword',
    async (login: string) => await ForgottenPasswordQuery(login),
  )

  const changePasswordUseMutation = useMutation<void, unknown, IChangePassword>(
    'forgottenPassword',
    async (changePassword: IChangePassword) => await ChangePasswordQuery(changePassword),
  )

  const contextValue = useMemo(
    () => ({
      isConnected,
      setIsConnected,
      throwAppException,
      onError,
      setOnError,
      alertMessage,
      setAlertMessage,
      onLogout,
      setOnLogout,
      fromApp,
      forgottenPasswordUseMutation,
      changePasswordUseMutation
    }),
    [
      isConnected,
      setIsConnected,
      throwAppException,
      onError,
      setOnError,
      alertMessage,
      setAlertMessage,
      onLogout,
      setOnLogout,
      fromApp,
      forgottenPasswordUseMutation,
      changePasswordUseMutation
    ]
  )

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

export default AppProvider

export const useApp = (): AppContextType => useContext<AppContextType>(AppContext)
