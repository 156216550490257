import React from 'react'
import {Avatar, List, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";

export interface IProps {
  step: number
  text: string
}

const BookingStepComponent = ({step, text}: IProps): JSX.Element =>
    <List className="labelFormAvatar">
      <ListItem dense={true} disableGutters={true}>
        <ListItemAvatar>
          <Avatar className="avatarSmall">
            {step}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={text}/>
      </ListItem>
    </List>

export default BookingStepComponent
