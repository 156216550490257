import React from 'react'
import {useTranslation} from "react-i18next";
import {Dialog, DialogContentText} from "@material-ui/core";

export interface IProps {
  open: boolean
  message: string
  onClose: () => void
}

const BookingModalComponent = ({open, message, onClose}: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Dialog
      fullWidth={true}
      onClose={onClose}
      open={open}
      className="ModalResponse"
    >
      <DialogContentText className="text-center pt-30 pb-30">
        {message}
      </DialogContentText>
      <button type="button" className="button" onClick={onClose}>
        {t('common.close')}
      </button>
    </Dialog>
  )
}

export default BookingModalComponent
