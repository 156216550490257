import {
  checkResponse,
  deleteRequestInit,
  getRequestInit,
  getUrl,
  postRequestInit
} from 'app/api/api'
import {
  IContractServiceFromApi,
  ICustomerReservation,
  INomadeCounter,
  INomadeCounterForPricing,
  IReservation,
  IReservationForPricing,
  IScheduleDesktop,
  IService
} from 'app/models/booking.model'

export const MeetingServiceListQuery = async (center: number): Promise<IService[]> => {
  return checkResponse(
    await fetch(
      getUrl(`scheduledesktop/listmeetingservices?center=${center}`),
      getRequestInit(true)
    )
  )
}

export const CommonMeetingServiceListQuery = async (): Promise<IService[]> => {
  return checkResponse(
    await fetch(
      getUrl(`scheduledesktop/listcommonservicesformeetingroom`),
      getRequestInit(true)
    )
  )
}

export const ScheduleDesktopListQuery = async (
  service: number,
  date: string,
  enterprise: number
): Promise<IScheduleDesktop[]> => {
  return checkResponse(
    await fetch(
      getUrl(`enterprises/${enterprise}/schedules?service=${service}&date=${date}`),
      getRequestInit(true)
    )
  )
}

export const CustomerReservationListQuery = async (
  enterprise: number,
  individualId: number
): Promise<ICustomerReservation[]> => {
  return checkResponse(
    await fetch(
      getUrl(`enterprises/${enterprise}/customerreservation?individualId=${individualId}`),
      getRequestInit(true)
    )
  )
}

export const PricingCustomerReservationQuery = async (
  enterprise: number,
  body: IReservationForPricing
): Promise<IReservation> => {
  return checkResponse(
    await fetch(
      getUrl(`enterprises/${enterprise}/pricer`),
      postRequestInit(true, JSON.stringify(body))
    )
  , true)
}

export const CreateCustomerReservationQuery = async (
  enterprise: number,
  body: IReservationForPricing
): Promise<IContractServiceFromApi> => {
  return checkResponse(
    await fetch(
      getUrl(`enterprises/${enterprise}/services`),
      postRequestInit(true, JSON.stringify(body))
    )
  , true)
}

export const DeleteContractServiceQuery = async (
  enterprise: number,
  id: number
): Promise<unknown> => {
  return checkResponse(
    await fetch(getUrl(`enterprises/${enterprise}/services/${id}`), deleteRequestInit(true))
  )
}

export const NomadeCounterQuery = async (
    enterprise: number,
    body: INomadeCounterForPricing
): Promise<INomadeCounter> => {
  return checkResponse(
      await fetch(
          getUrl(`enterprises/${enterprise}/nomadecounter?individual=${body.individualId}&center=${body.center}&date=${body.date}`),
          getRequestInit(true)
      )
  )
}
