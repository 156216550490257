import React from 'react';
import {ServiceTypeEnum} from "app/enums/booking.enum";
import {Box, Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";

export interface IProps {
  serviceType: ServiceTypeEnum;
  setServiceType: (serviceType: ServiceTypeEnum) => void;
}

interface IBookingType {
  type: ServiceTypeEnum;
  className?: string;
  label: string;
}

const data: IBookingType[] = [
  {
    type: ServiceTypeEnum.MEETING,
    className: 'meeting',
    label: 'meetingRoom'
  },
  {
    type: ServiceTypeEnum.OPEN_DESKTOP,
    className: 'openDesktop',
    label: 'desktop'
  }
]

const BookingTypeComponent = ({serviceType, setServiceType}: IProps): JSX.Element => {
  const {t} = useTranslation();

  return (
    <>
      {data.map((item: IBookingType, index: number) =>
        <Grid key={index} item xs={6} md={6} onClick={() => setServiceType(item.type)}>
          <Box
            component='div'
            py={1}
            mr={2}
            className={`pointer small-y serviceType ' ${serviceType === item.type ? 'select' : ''}`}
            border={2}
            borderRadius={4}
            textAlign='center'
          >
            <Box component='div' className={item.className} height={40}></Box>
            <Box mt={1} fontSize={14} mb={0} component="p" className="text-center">
              {t(`common.bookingView.bookingTypeComponent.${item.label}`)}
            </Box>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default BookingTypeComponent;
