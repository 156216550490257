import React, { Fragment, useCallback, useEffect, useState } from 'react'
import '@react-pdf-viewer/core/lib/styles/index.css'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import GetAppIcon from '@material-ui/icons/GetApp'
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import EuroIcon from '@material-ui/icons/Euro'
import EventIcon from '@material-ui/icons/Event'

import Logo from 'images/common/logo.png'
import { IPayment, IPaymentMethod } from 'app/models/payments.models'
import DocumentHelper from 'app/helpers/document.helper'
import CurrencyFormatter from 'app/formatters/currency.formatter'
import AccountingDocumentState from 'app/views/AccountingDocumentState.view'
import { AccountingDocumentStateEnum } from 'app/enums/accountingDocument.enum'
import DateFormatter from 'app/formatters/date.formatter'
import { PaymentTypeEnum } from 'app/enums/payment.enum'
import { useApp } from 'app/providers/app.provider'
import { useOnView } from 'app/providers/onview.provider'
import PaymentCbForm from 'modules/payment/views/payment_cb_form.view'
import 'modules/payment/css/payment.scss'

const PaymentCard = (): JSX.Element => {
  const { t } = useTranslation()
  const { isConnected } = useApp()
  const { paymentMethodListUseMutation, paymentGetUseMutation, gcMandateLinkGetUseMutation } = useOnView()
  const dateNow = new Date()

  const [payment, setPayment] = useState<IPayment | undefined>()
  const [url, setUrl] = useState<string>('')
  const [redirectUrlGc, setRedirectUrlGc] = useState<string>('')
  const [stripe, setStripe] = useState<Stripe | null>(null)
  const [hasMandate, setHasMandate] = useState<boolean>(false)
  const [isDisplayInvoice, setIsDisplayInvoice] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  const initPayment = useCallback(async (token: string) => {
    let _paymentMethods = await paymentMethodListUseMutation?.mutateAsync(token)

    if (undefined !== _paymentMethods) {
      let _hasMandate = false

      _paymentMethods.map((pm: IPaymentMethod) => {
        if (pm.active && pm.paymentType == PaymentTypeEnum.DEBIT_DIRECT) {
          _hasMandate = true
        }
      })

      setHasMandate(_hasMandate)

      const _payment = await paymentGetUseMutation?.mutateAsync(token)
      if (undefined !== _payment && undefined !== _payment.stripePublicKey) {
        const _stripe = await loadStripe(_payment.stripePublicKey)
        setStripe(_stripe)
      }

      let _url = ''
      if (undefined !== _payment && _payment?.file) {
        const _blob = DocumentHelper.base64toBlob(_payment.file)
        _url = URL.createObjectURL(_blob)
      }
      setUrl(_url)
      setPayment(_payment)
      setIsDisplayInvoice(true)

      if (undefined !== _payment && _payment.isEnterprise) {
        let _redirectFlowGc = await gcMandateLinkGetUseMutation?.mutateAsync(token)
        if (undefined !== _redirectFlowGc) {
          setRedirectUrlGc(_redirectFlowGc.redirectUrl)
        }
      }
    }
  }, [])

  useEffect(() => {
    let params = new URLSearchParams(window.location.search)
    const token = params.get('token')
    if (token !== null) {
      initPayment(token).then()
    }
  }, [])

  return (
    <>
      {payment !== undefined && (
        <Grid container spacing={3} className='quotationscard-container'>
          {!isConnected && (
            <Grid item xs={12} className='logoDevis'>
              <img src={Logo} className='logo' alt='' />
            </Grid>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper elevation={1}>
                <Box p={2}>
                  <div className='myCard payment_details'>
                    <div className='card-header list-header'>
                      {isConnected && (
                        <Link to='/enterprise/accountingdocuments/list' color='primary' className='icon-button'>
                          <ArrowBackIcon fontSize='small' />
                        </Link>
                      )}
                      <Typography variant='h2' color='primary'>
                        {t('common.my_invoice')} {payment.invoiceReference}
                      </Typography>
                    </div>
                    <div className='card-content'>
                      <Grid container spacing={3}>
                        <Grid item xl={3} md={4} xs={12}>
                          <ListItem className='default'>
                            <ListItemText
                              primary={
                                <div className='listItem-header-payment'>
                                  {payment.centerName}
                                  <a
                                    className=''
                                    href={url}
                                    download={`${payment.invoiceReference}.pdf`}
                                    title={t('common.download')}
                                  >
                                    <Avatar className='avatarColor small primary'><GetAppIcon
                                      fontSize='small' /></Avatar>
                                  </a>
                                </div>
                              }
                            />
                          </ListItem>
                          <Grid container spacing={3}>
                            <Grid item xs={6} className='pb-0'>
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar variant='rounded' className='avatarColor default outline'>
                                    <EuroIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    <Fragment>
                                      <span className='textColorMuted text-small'>{t('common.amount')}</span>
                                    </Fragment>
                                  }
                                  secondary={
                                    <Fragment>
                                      <span
                                        className='textColorDark text-normal'><strong>{CurrencyFormatter.formatFr(payment.dueAmount)}</strong></span>
                                    </Fragment>
                                  }
                                />
                              </ListItem>
                            </Grid>
                            <Grid item xs={6} className='pb-0'>
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar variant='rounded' className='avatarColor default outline'>
                                    <EventIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    <>
                                      <span className='textColorMuted text-small'>{t('common.state')}</span>
                                    </>
                                  }
                                  secondary={
                                    <Fragment>
                                      <span className='textColorDark text-normal'><strong><AccountingDocumentState
                                        state={payment.invoiceState} /></strong></span>
                                      {payment.paidAt != null && dateNow < new Date(payment.paidAt) && (
                                        <>
                                          <br />
                                          {t('common.payment_on')} : <strong>{DateFormatter.formatFrDateFromString(payment.paidAt)}</strong>
                                        </>
                                      )}
                                      {payment.paidAt != null && dateNow >= new Date(payment.paidAt) && (
                                        <>
                                          <br />
                                          {t('common.payment_payed_on')} : <strong>{DateFormatter.formatFrDateFromString(payment.paidAt)}</strong>
                                        </>
                                      )}
                                    </Fragment>
                                  }
                                />
                              </ListItem>
                            </Grid>
                            <Grid item xs={12} className='pt-0'>
                              <Divider></Divider>
                              {
                                payment.invoiceState !== AccountingDocumentStateEnum.DRAFT &&
                                payment.invoiceState !== AccountingDocumentStateEnum.CANCELLED &&
                                payment.invoiceState !== AccountingDocumentStateEnum.PAID &&
                                payment.invoiceState !== AccountingDocumentStateEnum.UNKNOWN &&
                                payment.invoiceState !== AccountingDocumentStateEnum.BLOCKED &&
                                (
                                  <>
                                    <div>
                                      {!hasMandate && redirectUrlGc !== '' &&
                                        (
                                          <Box
                                            textAlign='center'
                                            component='div'
                                            p={2}
                                            mt={2}
                                            mb={2}
                                          >
                                            <a href={redirectUrlGc} className='button bg-secondary w-174'>
                                              {t('common.activate_direct_debit')}
                                            </a>
                                          </Box>
                                        )}
                                      {!hasMandate && payment.isStripe && payment.dueAmount < 4000 && (
                                        <>
                                          <Box textAlign='center' component='div' p={2} mt={2} mb={2}>
                                            <span
                                              onClick={() => setOpen(!open)}
                                              aria-controls='example-collapse-text'
                                              aria-expanded={open}
                                              className='button bg-secondary w-174'>
                                              {t('common.pay_by_bank_card')}
                                            </span>
                                          </Box>
                                          <Collapse in={open}>
                                            <Box textAlign='center' component='div' p={2}>
                                              {stripe !== null && (
                                                <Elements stripe={stripe}>
                                                  <PaymentCbForm />
                                                </Elements>
                                              )}
                                            </Box>
                                          </Collapse>
                                        </>
                                      )}
                                      {hasMandate && payment.paidAt === null && (
                                        <>
                                          <br />
                                          <div dangerouslySetInnerHTML={{ __html: t('common.has_mandate') }} />
                                          <br /><br />
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                              <Divider></Divider>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xl={9} md={8} xs={12}>
                          {isDisplayInvoice && (
                            <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js'>
                              <div
                                style={{
                                  height: 'auto',
                                  width: 'auto'
                                }}
                              >
                                <Viewer fileUrl={url} />
                              </div>
                            </Worker>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default PaymentCard
