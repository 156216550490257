import React from 'react';
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";

export interface IProps {
  currentEnterpriseLabel: string
}

const BookingEnterpriseComponent = ({currentEnterpriseLabel}: IProps): JSX.Element => {
  const {t} = useTranslation();
  return (
    <Grid item xs={12} md={12} className="mb-20 flex-align-center textColorPrimary">
      <Typography>{t('common.bookingView.bookingEnterpriseComponent.label')} : {currentEnterpriseLabel}</Typography>
    </Grid>
  );
}

export default BookingEnterpriseComponent;