import React from 'react'
import Grid from '@material-ui/core/Grid'
import {useTranslation} from 'react-i18next'

import CenterCardComponent from 'modules/home/components/centerCard.component'
import ConciergeCardComponent from 'modules/home/components/conciergeCard.component'
import FoodCardComponent from 'modules/home/components/foodCard.component'

const Home = (): JSX.Element => {
  const {t} = useTranslation()

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <CenterCardComponent/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ConciergeCardComponent/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FoodCardComponent/>
        </Grid>
        <Grid item xs={12} className="text-center mt-20">
          <button
            type="button"
            onClick={() => (window.location.href = '/booking')}
            className="button w-174">
            {t('common.homeView.booking')}
          </button>
        </Grid>
      </Grid>
    </>
  )
}

export default Home
